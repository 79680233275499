import { Options, Vue }                    from "vue-class-component";
import { FilterMatchMode } from "primevue/api";

import { ZonesRoutesEnum }                 from "../../router";

import { zonesService }                    from "@services/zones.service";
import { SessionStorageEnum }              from "@/utils/SessionStorageEnum";

import {  
  AppTable,
} from "@components";

import { Zone } from "@/model/api/Zone";

@Options({
  components: {
    AppTable,
  },

  beforeRouteEnter(to, from, next) {
    if (from.name && !(from.name as string).startsWith(ZonesRoutesEnum.SMART_PREFIX)) {
      sessionStorage.removeItem(SessionStorageEnum.TABLE_STATE_ZONES);
    }

    next();
  },
  
  beforeRouteLeave() {
    if (this.service?.cancelPendingRequests) {
      this.service.cancelPendingRequests();
    }
  },
})
export default class SmartZonesPage extends Vue {
  filters: any = null;

  get service() {
    return zonesService;
  }

  get stateKey() {
    return SessionStorageEnum.TABLE_STATE_ZONES
  }

  get newZoneRoute(){
    return {
      name: ZonesRoutesEnum.SMART_DETAILS,
      params: { zoneId: 'new' }
    }
  }

  goToDetail(zone: Zone) {
    this.$router.push({
      name: ZonesRoutesEnum.SMART_DETAILS,
      params: { zoneId: zone.id }
    })
  }

  async onDelete(zone: Zone) {
    const response = await this.$confirmMessage(
      this.$t("smart_zone.messages.delete_confirm")
    );

    if (response) {
      this.deleteZone(zone);
    }
  }
  
  private deleteZone(zone: Zone) {
    this.$waitFor(
      async () => {
        await zonesService.deleteBySystem(zone);

        this.$successMessage(
          this.$t("smart_zone.messages.delete_success")
        );
      },
    );
  }
  
  private initFilter() {
    this.filters = {
      global: { 
        value: null, 
        matchMode: FilterMatchMode.CONTAINS 
      },
      name: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS 
      },
      w3: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS 
      },
      // zone_type: {
      //   value: ZoneTypeEnum.LOAD,
      //   matchMode: FilterMatchMode.EQUALS
      // },
      'client.name': {
        value: null,
        matchMode: FilterMatchMode.CONTAINS 
      },
    };
  }
  
  created() {
    this.initFilter();
  }
}
